import React, { useState } from 'react';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../firebaseConfig';

function LandingPage() {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Guardar el correo en Firebase Firestore
      await addDoc(collection(db, "emails"), {
        email: email
      });
      setSuccess(true);
      setEmail('');
    } catch (error) {
      console.error("Error al guardar el correo:", error);
    }
  };

  return (
    <div className="absolute w-screen h-screen">
      <video
        className="absolute top-0 left-0 h-full w-full object-cover brightness-75"
        preload="auto"
        autoPlay
        muted
        loop
      >
      <source
        src={`${process.env.PUBLIC_URL}/videos/header_video.mp4`}
        type="video/mp4"
      />
      </video>
      <div className="absolute w-screen h-screen flex flex-col justify-center items-center text-center p-4 sm:px-8">
					<img
      			alt="Viajes personalizados a medida con Sirocco"
      			src={`${process.env.PUBLIC_URL}/images/sirocco100x50.svg`}
      			className="w-full md:w-1/2"
      		/>
					<div className='flex flex-col w-full lg:w-1/2 lg:mb-1'>
					<p className="text-white text-base sm:text-xl mb-2 sm:mb-4">
        Estamos trabajando en la creación de experiencias de viaje únicas y a medida para ti. Desde destinos exóticos hasta escapadas culturales, en <strong>Sirocco</strong> diseñamos viajes personalizados que se adaptan a tus gustos, tiempos y presupuesto.
      </p>
      <p className="text-white text-base sm:text-xl mb-2 sm:mb-6">
        Regístrate ahora y obtén <strong>100eur de descuento</strong> en tu primer viaje personalizado.
      </p>

					</div>
      <form
      onSubmit={handleSubmit}
      className="flex flex-col sm:flex-row items-center w-full justify-center"
      >
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Introduce tu correo electrónico"
        className="px-4 py-2 m-2 rounded-full w-64 sm:w-80 bg-white text-black focus:outline-none"
        required
      />
      <button
        type="submit"
        className="px-6 py-2 m-2 rounded-full bg-blue-600 text-white hover:bg-blue-700 transition"
      >
        Enviar
      </button>
    </form>
    {success && (
      <p className="text-white mt-4">¡Gracias por registrarte! Te contactaremos pronto.</p>
    )}
  </div>
</div>);
}

export default LandingPage;
