import React from 'react';
import LandingPage from './components/LandingPage';

const App = () => {

  return (
    <LandingPage />
  );
};

export default App;
